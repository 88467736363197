<template>
  <navbar hide_verification_bar="true"></navbar>

  <div class="main-container" v-if="userInfo != null">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <h3>Hi there. Welcome to trbo!</h3>

        <div v-if="currentStep === 0">
          <div v-show="userInfo.email_verified">
            <img class="onboarding-image"
                 src="@/assets/img/onboarding/rocket_launch.webp" alt="Rocket launching gif" />
            <div class="image-attribution">Space shuttle launch / NASA</div>
          </div>
          <div v-show="!userInfo.email_verified">
            <img class="onboarding-image"
                 src="@/assets/img/onboarding/waiting.webp" alt="Waiting gif" />
            <div class="image-attribution">Mr. Bean / Rowan Atkinson / Universal Pictures</div>
          </div>

          <div v-if="!userInfo.email_verified">
            <div class="bot-chatter clearfix margin-top-element">
              <div class="icon">
                <i class="fa fa-hand-spock"></i>
              </div>
              <div class="body-text">
                <p>
                  Hi there! <br/>
                  You mentioned your email is: {{userInfo.email}}<br />
                  I need to verify this email exists before we can get started.
                </p>
                  Please check your inbox for an email from me? (I'll wait)<br />
                  (Please check your spam folder as well)
              </div>
            </div>
            <div class="mt-2">
              <button @click="checkForEmailVerification()" :disabled="checkingEmailVerification"
                      class="btn btn-primary my-2">
                <span v-if="!checkingEmailVerification">
                  <i class="fa fa-check"></i>&nbsp;
                  I verified my email. Let's go!
                </span>
                <span v-if="checkingEmailVerification">
                  <i class="fas fa-spin fa-spinner-third"></i>&nbsp;
                  Checking...
                </span>
              </button>
            </div>
            <a class="small"
               v-show="allowVerificationEmailSending && !sendingVerificationEmail"
               @click="sendVerificationEmail()"
               href="javascript:">I don't have the email. Send it again!</a>
            <span class="small" v-show="sendingVerificationEmail">
              <i class="fas fa-spin fa-spinner-third"></i>&nbsp;
              We are dispatching a verification email right now ...
            </span>
            <span class="small bold" v-show="!allowVerificationEmailSending">
              <i class="fa fa-check"></i>&nbsp;
              The verification email has been sent! Please check your inbox and spam folders!
            </span>
          </div>

          <div v-if="userInfo.email_verified">
            <div class="bot-chatter clearfix margin-top-element">
              <div class="icon">
                <i class="fa fa-robot fa-fw"></i>&nbsp;
              </div>
              <div class="body-text">
                My name is trbo <em>(pronounced turbo)</em>. Let's get you started!
              </div>
            </div>
            <button @click="goToStep1()" class="btn btn-primary wide-on-mobile margin-top-element">
              Let's Go!</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row onboarding-box" v-if="currentStep > 0">
      <div class="col-md-6 col-sm-12">
        <div v-if="currentStep === 1">
          <div class="steps">
            Step 1 of 2
          </div>
          <div class="question required">
            What is your current timezone?
          </div>
          <div class="margin-top-element">
            <select v-model="timeZone" class="form-control">
              <option></option>
              <option value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
              <option value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
              <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
              <option value="US/Alaska">(GMT-09:00) Alaska</option>
              <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
              <option value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
              <option value="US/Arizona">(GMT-07:00) Arizona</option>
              <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
              <option value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
              <option value="America/Managua">(GMT-06:00) Central America</option>
              <option value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
              <option value="America/Mexico_City">
                (GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
              <option value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
              <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
              <option value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
              <option value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
              <option value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
              <option value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
              <option value="America/Manaus">(GMT-04:00) Manaus</option>
              <option value="America/Santiago">(GMT-04:00) Santiago</option>
              <option value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
              <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
              <option value="America/Argentina/Buenos_Aires">
                (GMT-03:00) Buenos Aires, Georgetown</option>
              <option value="America/Godthab">(GMT-03:00) Greenland</option>
              <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
              <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
              <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
              <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
              <option value="Africa/Casablanca">
                (GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
              <option value="Etc/Greenwich">
                (GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
              <option value="Europe/Amsterdam">
                (GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
              <option value="Europe/Belgrade">
                (GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
              <option value="Europe/Brussels">
                (GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
              <option value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
              <option value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
              <option value="Asia/Amman">(GMT+02:00) Amman</option>
              <option value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
              <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
              <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
              <option value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
              <option value="Europe/Helsinki">
                (GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
              <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
              <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
              <option value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
              <option value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
              <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
              <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
              <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
              <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
              <option value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
              <option value="Asia/Baku">(GMT+04:00) Baku</option>
              <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
              <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
              <option value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
              <option value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
              <option value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi,
                Sri Jayawardenapura</option>
              <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
              <option value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
              <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
              <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
              <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
              <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
              <option value="Asia/Hong_Kong">
                (GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
              <option value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
              <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
              <option value="Australia/Perth">(GMT+08:00) Perth</option>
              <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
              <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
              <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
              <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
              <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
              <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
              <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
              <option value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
              <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
              <option value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
              <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
              <option value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
              <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
              <option value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
              <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
            </select>
          </div>
          <button
              @click="submitStep1()"
              class="btn btn-primary margin-top-element">Onwards!</button>&nbsp;

          <loader class="margin-top-element" v-show="isLoading"></loader>
        </div>

        <div v-if="currentStep === 2">
          <div class="steps">
            Step 2 of 2
          </div>
          <div class="question required">
            When should I send you reminder messages?
          </div>
          <div class="days-of-week">
            <label>
              <input value="1" type="checkbox" v-model="daysOfWeek"> Mon
            </label>
            <label>
              <input value="2" type="checkbox" v-model="daysOfWeek"> Tue
            </label>
            <label>
              <input value="3" type="checkbox" v-model="daysOfWeek"> Wed
            </label>
            <label>
              <input value="4" type="checkbox" v-model="daysOfWeek"> Thu
            </label>
            <label>
              <input value="5" type="checkbox" v-model="daysOfWeek"> Fri
            </label>
            <label>
              <input value="6" type="checkbox" v-model="daysOfWeek"> Sat
            </label>
            <label>
              <input value="0" type="checkbox" v-model="daysOfWeek"> Sun
            </label>
          </div>
          <div class="time-of-day">
            <select v-model="timeOfDay" class="wide-on-mobile form-control">
              <option v-for="ts in allTimes" v-bind:key="ts" :value="ts">{{ts}}</option>
            </select>
          </div>
          <button @click="submitStep2()" class="btn btn-primary margin-top-element">
            <i class="fa fa-check fa-fw"></i>
            Complete Setup!</button>&nbsp;

          <loader class="margin-top-element" v-show="isLoading" ></loader>

          <div class="bot-chatter margin-top-element clearfix">
            <div class="icon">
              <i class="fa fa-robot fa-fw"></i>
            </div>
            <div class="body-text">
              You can fine-tune this or turn these off from the settings.
            </div>
          </div>
        </div>

        <div v-if="currentStep === 3">
          <h5>Yay! You are all setup!</h5>
          <div>
            <img src="@/assets/img/onboarding/self_high_five.webp" alt="Self high five gif" />
            <div class="image-attribution">&copy; 30 Rock / Tina Fey / NBC</div>
          </div>
          <router-link class="btn btn-primary margin-top-element" to="/Dashboard">
            Proceed to the Dashboard!
          </router-link>
        </div>

      </div>
    </div>
    <div v-if="currentStep > 0 && currentStep <= 2">
      <div v-if="!skipSetupMode">
        <a class="small" @click="confirmSkipSetup()"
           href="javascript:">Skip Setup (Not Recommended)</a>
      </div>
      <div v-if="skipSetupMode">
        <a class="small" href="javascript:" @click="cancelSkipSetup()">
          Cancel</a>&nbsp;|&nbsp;<a class="small" href="javascript:" @click="skipSetup()">
          Click to confirm that you want to skip setup. (Not recommended)
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/app/auth';
import Navbar from '@/components/Navbar.vue';
import userInfo from '@/app/user-info';
import utils from '@/app/utils';
import schedule from '@/app/schedule';
import Loader from '@/components/Loader.vue';

export default {
  components: { Loader, Navbar },
  data() {
    return {
      timeZone: '',
      currentStep: 0,
      daysOfWeek: [1, 2, 3, 4, 5],
      timeOfDay: '6:00 PM',
      userInfo: null,
      isLoading: false,
      skipSetupMode: false,
      sendingVerificationEmail: false,
      allowVerificationEmailSending: true,
      checkingEmailVerification: false,
    };
  },
  computed: {
    allTimes() {
      return utils.getTimesInDay();
    },
  },
  async mounted() {
    this.userInfo = await userInfo.fetchUser(await auth.getUserIdToken());
    if (this.userInfo.on_boarded === true) {
      this.currentStep = 3;
    }

    this.timeZone = this.userInfo.time_zone;
  },
  methods: {
    confirmSkipSetup() {
      this.skipSetupMode = true;
    },
    cancelSkipSetup() {
      this.skipSetupMode = false;
    },
    async skipSetup() {
      await this.finishSetup();
    },
    async submitStep1() {
      if (!this.timeZone || this.timeZone === '') {
        utils.showToastError('Please select a timezone to continue',
          'I need a timezone from you to send reminders at the right time. '
            + 'Please select one from the list to continue. Thank you!', true);
        return;
      }

      this.isLoading = true;

      try {
        await userInfo.updateTimezone(await auth.getUserIdToken(), this.timeZone);
        this.isLoading = false;
        this.currentStep = 2;
      } catch {
        utils.showToastError('Could not update timezone',
          'An error occurred and I could not update the timezone for you. '
            + 'Please refresh the page and try again.', true);
      }
    },
    goToStep1() {
      this.currentStep = 1;
    },
    async submitStep2() {
      // Validation
      if (this.daysOfWeek.length === 0) {
        utils.showToastError('Please select one or more days.',
          'You need to select one or more days of the week to continue. <br />'
            + 'You can change this later from the settings.', true);
        return;
      }

      this.isLoading = true;

      try {
        await schedule.createBatchSchedules(await auth.getUserIdToken(),
          this.daysOfWeek, this.timeOfDay);
        this.isLoading = false;

        await this.finishSetup();
      } catch {
        utils.showToastError('I could not create the schedules for you',
          'An error occurred and I could not create the schedules for you. <br />'
            + 'Please refresh the page and try again. Sorry about that!', true);
      }
    },
    async finishSetup() {
      this.currentStep = 3;

      // Set on boarding to complete
      await userInfo.setOnBoardingFlag(await auth.getUserIdToken(), true);
    },
    async sendVerificationEmail() {
      this.sendingVerificationEmail = true;
      await userInfo.sendVerificationEmail(await auth.getUserIdToken());
      this.sendingVerificationEmail = false;
      this.allowVerificationEmailSending = false;
      setTimeout(() => {
        this.allowVerificationEmailSending = true;
      }, 10000);
    },
    async checkForEmailVerification() {
      this.checkingEmailVerification = true;
      this.userInfo = await userInfo.fetchUser(await auth.getUserIdToken());
      if (!this.userInfo.email_verified) {
        utils.showToastError('Email not verified',
          'The email is still not verified. Can you please try again? <br />You need to click on the link in the email.',
          true);
      }
      this.checkingEmailVerification = false;
    },
  },
};
</script>
